<template>
  <div class="merchant-apply">
    <GlobalInfoBar
        title="入驻申请"
        content="回收商家在小程序端提交入驻申请，需后台运营者审核通过后，回收商家方可使用，审核成功后需完善商家资料"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
        :padding="[20,20,20,20]"
    >

      <div slot="filter">
        <Form @changeNav="changeNavFrom"></Form>
      </div>
      <!-- <GlobalNav
          :navList="[
        {id:'', name: '全部', numKey: 'index'},
        {id:'00', name: '待审核', numKey: 'index1', isRed: true},
        {id:'01', name: '审核通过', numKey: 'index2'},
        {id:'02', name: '审核拒绝', numKey: 'index3'},
      ]"
          :initNav="{id:'', name: '全部', numKey: 'index'}"
          :numList="{index: totalIndex,index1:totalIndex1,index2:totalIndex2,index3:totalIndex3}"
          @changeNav="changeNav"
          style="margin-bottom: 21px;"
      /> -->

      <Table :tableData="tableDataIndex" :applytotal="applytotal" @handleCurrentChange="handleCurrentChange"></Table>
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form"
import Table from "./Table"
import _api from "@/utils/request";
export default {
  name: 'merchant-apply',
  components: {Form, Table},
  
  data(){
    return{
      tableDataIndex:[],
      totalIndex:"",
      totalIndex1:"",
      totalIndex2:"",
      totalIndex3:"",
      merchantName:"",
      contactPhone:"",
      applyStatus:"",
      applytotal:0,
      applypageNum:1,
      applypageSize:1,
      SeachParams: {}
    }
  },
  created(){
    this.ApplyDataList()
  },
  methods: {
    //分页
    handleCurrentChange(val){
      this.applypageNum=val,
      this.ApplyDataList()
    },
    //查询
    changeNavFrom(val){
      this.contactPhone = val.contactPhone,
      this.merchantName = val.merchantName
      this.SeachParams = val
      if(this.applypageNum===1){
        console.log(this.applypageNum)
      }else{
        this.applypageNum = 1
      }
      this.ApplyDataList()
    },
    //tab页
    changeNav(val) {
      this.applyStatus = val.id
      this.ApplyDataList()
    },
    //列表请求
    ApplyDataList(){
      const vo = {
        merchantType:"02",
        applyStatus:this.applyStatus,
        pageNum:this.applypageNum,
        pageSize:10,
        contactPhone:this.contactPhone,
        merchantName:this.merchantName,
      }
      _api.ApplyTable(vo).then(res =>{
        if(res.code===1){
          this.tableDataIndex = res.data.page.records
          this.applytotal = res.data.page.total
          this.totalIndex = res.data.countDto.allCount
          this.totalIndex1 = res.data.countDto.toAuditCount
          this.totalIndex2 = res.data.countDto.passAuditCount
          this.totalIndex3 = res.data.countDto.refuseAuditCount
        }
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      })
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-apply{}
</style>
