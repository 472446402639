<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="applypageNum"
      :total="applytotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="省市区" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
            <span>{{row.province}}{{row.city}}{{row.region}}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.merchantAddress" placement="top">
            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">{{row.merchantAddress}}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="营业执照" slot="operation" align="center">
        <template slot-scope="scope">
            <span @click="DialoghandleBtn(scope.row)" class="tableBtn">
              <span v-if="scope.row.businessLicense!==null"><span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">查看图片</span></span>
              <span v-if="scope.row.businessLicense===null">--</span>
            </span>
          </template>
      </el-table-column>
      <el-table-column label="审核状态" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.applyStatus==='00'" class="StatusBtn1">待审核</span>
          <span v-else-if="row.applyStatus==='01'" class="StatusBtn2">审核通过</span>
          <span v-else-if="row.applyStatus==='02'" class="StatusBtn3">审核拒绝</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span @click="handleBtn(row, 'remark')" v-if="row.remark===null" class="remarkBtn">添加备注</span>
          <span @click="handleBtn(row, 'remarkCheck')" v-if="row.remark!==null" class="remarkBtn">查看备注</span>
        </template>
      </el-table-column>
      <!-- <el-table-column width="180" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.applyStatus==='00'">
            <el-button size="mini" type="primary" @click="handleBtn(row, 'Review')">审核</el-button>
          </span>
          <span v-if="row.applyStatus!=='00'">
            <el-button size="mini" type="primary" @click="handleBtn(row, 'ReviewHistory')">审核记录</el-button>
          </span>
        </template>
      </el-table-column> -->
    </GlobalTable>
    <DialongRemark
      :QualityDialogFlag.sync="QualityDialogFlag"
      :title="title"
      :showBtn='true'
      @QualityDialogSub="QualityDialogSub"
    >
      <div v-if="radioShow" class="radioTitle">
        <div><img src="../../../../assets/images/imgAll/ico_ts_red@2x.png" alt=""></div>
        <div>说明：审核通过，系统将自动创建商户账号，请及时完善商户信息，如未通过审核必须告知商户原因，请填写在备注栏</div>
      </div>
      <div v-if="radioShow" class="radioDialog">
        <el-radio v-model="radio" label="01">通过审核</el-radio>
        <el-radio v-model="radio" label="02">不通过审核</el-radio>
      </div>
      <div v-if="remarkShowDetails">
        <span v-if="remarkShow === true">
          <!-- //审核输入框 -->
          <el-input type="textarea" :maxlength="this.remarkMaxlength" :rows="4" :placeholder="this.remarkPlaceholder" v-model="textareaAdd"></el-input>
        </span>
        <span v-if="remarkShowCheck ===true">
          <!-- !-- //备注输入框  -->
          <el-input type="textarea" :rows="4" placeholder="请输入备注内容（200字内）" v-model="textareaCheck" maxlength="200"></el-input>
        </span>
      </div>
      <div class="review_Details" v-if="reviewDetails">
        <div class="DetailsRow">
          <span>审核状态：</span>
          <span v-if="applyStatusRecord==='00'">待审核</span>
          <span v-if="applyStatusRecord==='01'">审核通过</span>
          <span v-if="applyStatusRecord==='02'">审核拒绝</span>
        </div>
        <div class="DetailsRow">
          <span>审核时间：</span>
          <span>{{applyTimeRecord}}</span>
        </div>
        <div class="DetailsRow">
          <span>审核人员：</span>
          <span>{{applyManagerRecord}}</span>
        </div>
        <div class="DetailsRow">
          <span>备注说明：</span>
          <span>{{applyRemarkRecord}}</span>
        </div>
      </div>
    </DialongRemark>
    <!--大图弹框-->
    <!-- <GlobalDialog
      title='营业执照'
      :show="dialogVisibleOrder"
      width="100%"
      top="2vh"
      @resetPopupData="resetPopupData"
      @submitPopupData="resetPopupData"
      @onClose="resetPopupData"
    > 
    <viewer :images="DialogImgList" style="height: 800px;">
          <div class="sec_img" height="80vh">
              <img :src="this.DialogImgList" class="coverImg">  
          </div>
    </viewer>
    </GlobalDialog> -->
    <el-image-viewer
      v-if="dialogVisibleOrder"
      :on-close="() => {dialogVisibleOrder = false}"
      :url-list="DialogImgList"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  props: ["tableData", "applytotal","applypageNum", "merchantType"],
  name: "Table",
  components: { ElImageViewer },
  data() {
    return {
      dialogVisibleOrder:false,//营业执照大图展示
      DialogImgList:[],//营业执照大图数组
      merchantName:"",
      remarkPlaceholder:"",
      remarkMaxlength:'',
      paramsDialog: "",
      radio: "01",
      remarkShowCheck:false,
      radioShow: false,
      reviewDetails:false,
      remarkShowDetails:true,
      remarkShow: Boolean,
      QualityDialogFlag: false,
      title: "",
      textareaAdd: "",
      textareaCheck: "",
      merchantId: "",
      applyStatus: "",
      applyManagerRecord:"",//审核人员(审核详情)
      applyRemarkRecord :"",//审核记录(审核详情)
      applyStatusRecord :"",//审核状态(审核详情)
      applyTimeRecord :"",//审核时间(审核详情)
      remarkAdd: "", //添加备注提交
      ReviewParams: "", //审核提交
      ReviewConfirm:'',
      tableColumns: [
        { label: "商家名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        {slotName: 'operationAddress'},
        { label: "申请入驻时间", prop: "entryTime" },
        { slotName: "operation" }
      ]
      // tableData: [],
    };
  },
  created() {},
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val)
      console.log(val)
    },
    // 营业执照查看大图
    DialoghandleBtn(row){
      console.log(row.businessLicense)
      this.DialogImgList = []
      this.DialogImgList.push(row.businessLicense)
      this.dialogVisibleOrder = true;
    },
    //营业执照弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    // 表格操作
    handleBtn(row, type) {
      (this.merchantId = row.merchantId), (this.applyStatus = row.applyStatus);
      console.log("表格操作", type, row);
      console.log(this.applyStatus)
      this.QualityDialogFlag = true;
      //审核
      if (type === "Review") {
        this.remarkPlaceholder = '请输入审核备注内容（50字内）'
        this.remarkMaxlength = 50
        this.ReviewConfirm = 'Review'
        this.remarkShowDetails = true
        this.remarkShowCheck = false
        this.reviewDetails = false;
        this.radioShow = true;
        this.remarkShow = true;
        this.title = `审核申请-${row.merchantName}`;
      }
      //审核记录
      else if (type === "ReviewHistory") {
        this.ReviewConfirm = ''
        this.remarkShowCheck = false
        this.remarkShow = false;
        this.reviewDetails = true;
        this.radioShow = false;
        this.remarkShowDetails=false
        this.title = `审核记录-${row.merchantName}`
        _api.infoAudit(this.merchantId).then(res => {
          if(res.code === 1){
            this.applyManagerRecord = res.data.applyManager
            this.applyRemarkRecord = res.data.applyRemark
            this.applyStatusRecord = res.data.applyStatus
            this.applyTimeRecord = res.data.applyTime
          }
        })
      }
      //添加备注
      else if (type === "remark") {
          this.textareaCheck =""//赋空查看的备注值
          this.ReviewConfirm = ''
          this.remarkPlaceholder = '请输入备注内容（200字内）'
          // this.remarkMaxlength = 200
          this.remarkShowDetails = true
          this.remarkShowCheck = true
          this.reviewDetails = false;
          this.radioShow = false;
          this.remarkShow = false;
          this.title = "添加备注";
      }
      else if (type==='remarkCheck'){
          this.remarkShowDetails = true
          this.reviewDetails = false;
          this.remarkShowCheck = true
          this.radioShow = false;
          this.remarkShow = false;
          this.textareaCheck = row.remark;
          this.title = "查看备注";
      }
    },
    //弹窗确定
    QualityDialogSub() {
      console.log(this.merchantId)
      if (this.radioShow === true) {
        this.paramsDialog = {
          applyRemark: this.textareaAdd,//审核备注
          applyStatus: this.radio,//审核状态
          merchantId: this.merchantId,//主键id
          remark: "",//添加备注
          type: "02"//操作类型（01：添加备注，02:审核操作）
        };
      } else {
        this.paramsDialog = {
          applyRemark: "",
          merchantId: this.merchantId,
          remark: this.textareaCheck,
          applyStatus: this.applyStatus,
          type: "01"
        };
      }
      _api.modifyAuditOper(this.paramsDialog).then(res => {
        if (res.code === 1) {
          this.handleCurrentChange()
          this.QualityDialogFlag = false;
          if(this.ReviewConfirm === 'Review'&&this.radio=='01'){
            this.$confirm('你已完成审核, 是否继续完善资料?', '审核通过', {
            confirmButtonText: '马上完善',
            cancelButtonText: '暂不处理',
            type: 'warning'
            }).then(() => {
              this.$router.push({path:"/merchant/add",query:{type: 'edit',id: this.merchantId}})
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });          
            });
          }else{
            this.$message.success(res.msg);
          }
        }
      })
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .el-button--mini {
    width: 100px !important;
  }
  .remarkBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .StatusBtn1 {
    color: #ff687b;
  }
  .StatusBtn1 {
    color: #80c269;
  }
  .StatusBtn1 {
    color: #ffaf29;
  }
  .radioDialog {
    margin-bottom: 15px;
    color: #333333;
  }
  .radioTitle{
    display: flex;
    margin-bottom: 15px;
    height: 33px;
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #FF8080;
    div:first-child{
      width: 16px;
      height: 16px;
      margin: 3px;
    }
  }
  .review_Details{
    height: 80%;
    .DetailsRow{
      margin: 10px;
      span:first-child{
        color: black;
      }
    }
  }
  .sec_img {
      width: 20vw;
      height: 80vh;
      margin: auto;
      img{
        width: 20vw;
        height: 80vh;
        object-fit: contain;
      }
    }
}
</style>
