<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    />
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
export default {
  name: 'Form',
  data() {
    return {
      initData: null,
      formItemList: [
        {
          key: 'staffName',
          type: 'input',
          labelName: '商家名称：',
          placeholder: '请输入商家名称'
        },
        {
          key: 'mobile',
          isNumber: true,
          type: 'input',
          labelName: '联系电话：',
          placeholder: '请输入联系电话'
        }
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    handleConfirm(data, cd) {
      console.log('提交了form', data)
      const SeachParams = {
        merchantName:data.staffName,
        contactPhone:data.mobile,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.$emit("changeNav",SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
